.print {
  width: 310mm;
  height: 296mm;
  padding: 20mm;
  background: #ffffff;
  // margin: 0 auto;
  section {
    width: 100%;
  }
  .registration {
    section .ant-row {
      margin-bottom: 2mm;
    }
  }
}

//untuk membuka opsi portrait & landscape
.print-auto {
  width: 100%;
  height: auto;
  page-break-before: always;
  page: orientation-auto;
  section {
    width: 100%;
  }
}

@media print {
  @page orientation-auto {
    size: auto;
    margin: 15mm;
  }

  //agar page lain yang menggunakan .print tetap terkunci ke portrait
  @page {
    size: portrait;
  }

  h1 {
    color: black !important;
  }

  .ant-table-content {
    font-size: 18px !important;
  }

  .ant-table-content .ant-typography {
    font-size: 18px !important;
  }

  .ant-table {
    background-color: white !important;
  }

  th.ant-table-cell::before {
    background-color: white !important;
  }

  td.ant-table-cell {
    border-bottom: 1px solid #f0f0f0 !important;
  }

  .ant-table-cell {
    padding: 12px 8px !important;
    background-color: white !important;
  }

  .ant-card-body {
    background-color: white !important;
    border-radius: 0 !important;
  }

  .ant-table-row {
    background-color: white !important;
  }

  .ant-table-summary tr {
    background-color: #f1f4f8 !important;
  }

  .ant-table-summary .ant-table-cell {
    white-space: nowrap;
    background-color: #f2f4f8 !important;
  }

  .ant-table-summary .ant-table-cell .ant-typography {
    font-size: 18px !important;
  }

  .ant-table-thead .ant-table-cell {
    background-color: #f2f4f8 !important;
    color: black !important;
    border-bottom: 1px solid #f0f0f0 !important;
  }

  .ant-table-thead .ant-table-column-title {
    color: black !important;
  }

  .print-wrap div .card {
    background-color: white !important;
    color: black !important;
    .amount {
      color: black !important;
    }
  }

  .ant-tag {
    border: 1px solid #d9d9d9;
    background-color: #fafafa !important;
    color: black !important;
  }

  .ant-table-custom {
    font-size: 18px !important;
  }

  html.dark div::-webkit-scrollbar-track {
    background-color: white;
  }

  html.dark div::-webkit-scrollbar-thumb {
    display: none;
    border-radius: 20px;
  }

  .edit-dashboard {
    background-color: white !important;
  }

  .ant-layout-content {
    background-color: white !important;
  }

  html.dark body {
    background: white !important;
  }

  td,
  td * {
    color: black !important;
  }

  aside,
  header,
  footer,
  button,
  input,
  [class*='breadcrumbs'],
  .custom-pagination,
  .ant-alert,
  .ant-pagination,
  .ant-message,
  .ant-checkbox-wrapper,
  .ant-btn-group,
  .ant-calendar-picker,
  .ant-menu,
  .ant-input-affix-wrapper,
  .ant-table-column-sorter,
  .ant-dropdown-trigger,
  .ant-menu-submenu,
  .ant-menu-submenu-popup,
  .anticon-more,
  .anticon-setting,
  .hide-on-print,
  .ant-picker {
    display: none !important;
  }

  .utils__content,
  .print {
    display: block !important;
    background-color: white;
  }

  .print-auto {
    display: block !important;
    background-color: white;
  }

  body {
    background-color: white;
  }

  .ant-card-bordered {
    border: none !important;
  }

  .print-wrap {
    flex-wrap: wrap;
  }

  .print-graph-none {
    display: none !important;
  }

  .chartjs-render-monitor {
    width: 100% !important;
    height: auto !important;
  }

  /* Khusus untuk landscape, penyesuaian ukuran */
  @media print and (orientation: landscape) {
    .ant-table-content {
      font-size: 14px !important;
    }

    .ant-table-content .ant-typography {
      font-size: 14px !important;
    }

    .ant-table-summary .ant-table-cell .ant-typography {
      font-size: 14px !important;
    }
  }
}
