.ant-table-tbody-custom>tr>td,
.ant-table-thead-custom>tr>th,
.ant-table-custom tfoot>tr>td,
.ant-table-custom tfoot>tr>th {
  position: relative;
  padding: 12px 8px;
  overflow-wrap: break-word;
  color: #000;
}

.ant-table-tbody-custom>tr>td {
  border-bottom: 1px solid #f0f0f0;
  transition: background .3s;
}

.ant-table-tbody-custom>tr:hover {
  background-color: $gray-lighten-more;
}

.ant-table-custom table {
  width: 100%;
  text-align: left;
  border-radius: 2px 2px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}

.ant-table-thead-custom>tr>th {
  position: relative;
  color: rgba(0,0,0,.85);
  font-weight: 500;
  text-align: left;
  background: #f2f4f8;
  border-bottom: 1px solid #f0f0f0;
  transition: background .3s ease;
}

.ant-empty {
  margin: 0 8px;
  font-size: 13px;
  line-height: 1.5715;
  text-align: center;
}

.ant-empty-normal {
  margin: 32px 0;
  color: rgba(0,0,0,.25);
}

.ant-pagination-total-text {
  display: inline-block;
  height: 32px;
  margin-right: 8px;
  line-height: 30px;
  vertical-align: middle;
}

.ant-dropdown-menu-item-icon {
  font-size: 12px !important;
}

.infinite-loader {
  background-color: #fafafa;
}

.dark {
  .ant-table-thead-custom>tr>th {
    color: white;
    background: $dark-5;
    border-bottom: 1px solid $dark-5;
  }

  .ant-table-tbody-custom>tr:hover {
    background-color: $dark-5;
  }

  .ant-table-tbody-custom>tr>td {
    border-bottom: 1px solid $dark-5;
    transition: background .3s;
    color: white;
  }

  .ant-table-thead>tr>td {
    position: relative;
    color: white;
    font-weight: 500;
    text-align: left;
    background: $dark-5;
    transition: background .3s ease;
  }

  p.ant-empty-description {
    color: $dark-5;
  }

  .infinite-loader {
    background-color: #111A2C;
  }

  .ant-modal-content {
    background-color: $dark-3 !important;

    .ant-modal-confirm-title {
      color: #DADADA !important;
    }
  }
}
